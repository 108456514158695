import DoxAnalytics from "@dox/analytics";
import UrlPattern from "url-pattern";

window.doxAnalytics = window.DoxAnalytics || new DoxAnalytics()

const doxAnalytics = window.doxAnalytics;

const PRODUCT = "foundation";

window.initAnalytics = ({ release, userId } = {}) => {
  doxAnalytics.init({
    application: "doximity/foundation",
    release,
  });

  if (userId) {
    doxAnalytics.identify({ userId });
  }
};

const PAGE_ROUTE_MAP = {
  homepage: new UrlPattern("/"),
  projects_listing_page: new UrlPattern("/projects"),
  project_detail_page: new UrlPattern("/projects/:projectId"),
  how_it_works_page: new UrlPattern("/how_it_works"),
  contact_us_page: new UrlPattern("/contact_us")
};

const matchRoute = path => {
  for (const view in PAGE_ROUTE_MAP) {
    if (PAGE_ROUTE_MAP[view].match(path)) return view;
  }
};

document.addEventListener("turbolinks:load", () => {
  const view = matchRoute(document.location.pathname);

  if (view) {
    doxAnalytics.page({
      product: PRODUCT,
      view
    });
  }
});

document.addEventListener("turbolinks:load", () => {
  const sections = {};

  const setupTracking = (el, listener) => {
    let sectionName = el.dataset.daSection;
    let impressionName = el.dataset.daImpression;
    let properties = el.dataset.daProperties;

    const section =
      (sections[sectionName] && sections[sectionName].section) ||
      doxAnalytics.registerSection({ product: PRODUCT });

    sectionName = sectionName || section.id;

    if (!sections[sectionName]) {
      sections[sectionName] = {
        section,
        impressions: {}
      };
    }

    const impression =
      sections[sectionName].impressions[impressionName] ||
      section.registerImpression();

    impressionName = impressionName || impression.id;

    if (!sections[sectionName].impressions[impressionName]) {
      sections[sectionName].impressions[impressionName] = impression;
    }

    impression[listener](
      el.dataset.daEvent,
      el,
      properties && JSON.parse(properties)
    );
  };

  document
    .querySelectorAll("[data-da-track-shown]")
    .forEach(el => setupTracking(el, "trackShown"));
  document
    .querySelectorAll("[data-da-track-tapped]")
    .forEach(el => setupTracking(el, "trackTapped"));
  document
    .querySelectorAll("[data-da-track-entered]")
    .forEach(el => setupTracking(el, "trackEntered"));
});

document.addEventListener("turbolinks:before-render", () => {
  doxAnalytics.sections.forEach(section => {
    doxAnalytics.unregisterSection(section.id);
  });
});
